$primary-color: #0c1727;
$secondary-color: white;
$neutral-color: #818890;
$neutral: #f3f3f3;
$elementary-color: #3ab7fd;
$t-gap: 0.5rem;
$sm-gap: 1rem;
$lg-gap: 2rem;
$ex-gap: 10rem;
$md-gap: 5rem;
$border: 1px solid #818890;
$trans: 0.3s ease-in-out;
$shadow: 0px 30px 40px rgba(153, 153, 153, 0.262);
$elementary-shadow: 0px 30px 40px #3ab7fd;
$text-gradient: linear-gradient( 90deg, #7964de, #ad6ce5, #db75eb, #f395ae, #fab466);